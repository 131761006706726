import {formatCount, formatDuration, formatTimeAgo} from '../util/formatUtil';
import {UserBadge} from '../util/userUtil';
import {useState} from 'react';
import {QualityFlags, ThumbnailFlags} from '../shared/bitsets';
import Link from 'next/link';
import {I18nText} from '../i18n/I18nText';

export default function Video({video, inList = false, showUploader = true, showTimeAgo = false}) {
    let [hoveredThumbnail, setHoveredThumbnail] = useState(false);

    let thumbnailURL = 'https://cornhub.objectdepot.net/static/no_thumbnail.jpg';
    if (hoveredThumbnail && (video.thumbnails & ThumbnailFlags.Animated) !== 0) {
        thumbnailURL = `https://cornhub.objectdepot.net/thumbnail/${video.link_id}/animated.gif`;
    } else if ((video.thumbnails & ThumbnailFlags.HQ) !== 0) {
        thumbnailURL = `https://cornhub.objectdepot.net/thumbnail/${video.link_id}/hq.jpg`;
    } else if ((video.thumbnails & ThumbnailFlags.LQ) !== 0) {
        thumbnailURL = `https://cornhub.objectdepot.net/thumbnail/${video.link_id}/lq.jpg`;
    }

    let likePercentage = 50;
    if (video.likes > 0 || video.dislikes > 0) {
        const total = video.likes + video.dislikes;
        likePercentage = Math.round((video.likes / total) * 100);
    }

    if (inList) {
        return <div className="mt-1 flex">
            <Link href={`/video/${video.link_id}`} className="relative w-4/7">
                <div onMouseEnter={() => setHoveredThumbnail(true)}
                     onMouseLeave={() => setHoveredThumbnail(false)}>
                    <div
                        className={`w-full ${hoveredThumbnail ? 'bg-black' : 'bg-gray-900'} thumbnail-aspect-ratio bg-no-repeat bg-contain`}
                        style={{
                            backgroundImage: `url(${thumbnailURL})`,
                            backgroundPosition: '50% 50%'
                        }}>
                    </div>
                    <div className="absolute inset-0 z-10 grid">
                        <span className="r-0 b-0 justify-self-end self-end p-1">
                            {(video.qualities & QualityFlags.P720) !== 0 ?
                                <span className="font-bold">HD </span> : null}
                            {formatDuration(video.length)}
                        </span>
                    </div>
                </div>
            </Link>
            <div className="w-3/7 ml-1">
                <Link href={`/video/${video.link_id}`}>
                    <h2>{video.title}</h2>
                </Link>
                {showUploader ? <h3 className="text-slate-400">
                    <Link href={`/model/${video.uploader.username}`}>
                        <UserBadge user={video.uploader} className="inline mr-1"/>
                        {video.uploader.display_name ?? video.uploader.username}
                    </Link>
                </h3> : null}
                <Link href={`/video/${video.link_id}`}>
                    <h3 className="text-slate-400">
                        <I18nText id={video.views !== 1 ? 'video.view_count.plural' : 'video.view_count.single'}
                                  placeholders={{count: formatCount(video.views)}}/>
                        <img
                            src={video.my_rating === undefined || video.my_rating === null ? 'https://cornhub.objectdepot.net/static/icons/like.svg' : (video.my_rating ? 'https://cornhub.objectdepot.net/static/icons/liked.svg' : 'https://cornhub.objectdepot.net/static/icons/disliked.svg')}
                            width={12} height={12} className={`inline ml-1`} alt="Like"/> {likePercentage}%
                    </h3>
                </Link>
                {showTimeAgo ? <h3 className="text-slate-400">
                    {formatTimeAgo(new Date(video.created_at))}
                </h3> : null}
            </div>
        </div>;
    }

    return <div>
        <Link href={`/video/${video.link_id}`}>
            <div className="relative w-full"
                 onMouseEnter={() => setHoveredThumbnail(true)}
                 onMouseLeave={() => setHoveredThumbnail(false)}>
                <div
                    className={`w-full ${hoveredThumbnail ? 'bg-black' : 'bg-gray-900'} thumbnail-aspect-ratio bg-no-repeat bg-contain`}
                    style={{
                        backgroundImage: `url(${thumbnailURL})`,
                        backgroundPosition: '50% 50%'
                    }}/>
                <div className="absolute inset-0 z-10 grid">
                <span className="r-0 b-0 justify-self-end self-end p-1">
                    {(video.qualities & QualityFlags.P720) !== 0 ? <span className="font-bold">HD </span> : null}
                    {formatDuration(video.length)}
                </span>
                </div>
            </div>
            <h1>{video.title}</h1>
        </Link>
        {showUploader ? <h3 className="text-slate-400">
            <Link href={`/model/${video.uploader.username}`}>
                <UserBadge user={video.uploader} className="inline mr-1"/>
                {video.uploader.display_name ?? video.uploader.username}
            </Link>
        </h3> : null}
        <Link href={`/video/${video.link_id}`}>
            <h3 className="text-slate-400">
                <I18nText id={video.views !== 1 ? 'video.view_count.plural' : 'video.view_count.single'}
                          placeholders={{count: formatCount(video.views)}}/>
                <img
                    src={video.my_rating === undefined || video.my_rating === null ? 'https://cornhub.objectdepot.net/static/icons/like.svg' : (video.my_rating ? 'https://cornhub.objectdepot.net/static/icons/liked.svg' : 'https://cornhub.objectdepot.net/static/icons/disliked.svg')}
                    width={12} height={12} className={`inline ml-1`} alt="Like"/> {likePercentage}%
            </h3>
        </Link>
    </div>;
}
