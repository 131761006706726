export function formatDuration(ms) {
    const sec = Math.floor((ms % 60000) / 1000);
    const min = Math.floor(ms / 60000);

    return `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
}

export function formatCount(views) {
    if (views < 1_000) return views;

    let m = views >= 1_000_000;

    return `${(views / (m ? 1_000_000 : 1_000)).toFixed(1).replace('.0', '')}${m ? 'M' : 'K'}`;
}

const epochs = [
    ['year', 31536000],
    ['month', 2592000],
    ['day', 86400],
    ['hour', 3600],
    ['minute', 60],
    ['second', 1]
];

function getDuration(timeAgoInSeconds) {
    for (let [name, seconds] of epochs) {
        const interval = Math.floor(timeAgoInSeconds / seconds);
        if (interval >= 1) {
            return {
                interval: interval,
                epoch: name
            };
        }
    }
}

export function formatTimeAgo(date) {
    const timeAgoInSeconds = Math.floor((new Date() - new Date(date)) / 1000);
    if (timeAgoInSeconds === 0) {
        return 'now';
    }
    const {interval, epoch} = getDuration(timeAgoInSeconds);
    const suffix = interval === 1 ? '' : 's';
    return `${interval} ${epoch}${suffix} ago`;
}
