'use client';
import {useEffect} from 'react';

export function useApplyAdsenseWhenPageLoaded() {
    useEffect(() => {
        let stateCheck = setInterval(() => {
            if (document.readyState === 'complete') {
                clearInterval(stateCheck);
                try {
                    (window.adsbygoogle || []).push({});
                } catch(e) {
                    console.error('Ignoring adsense error:', e);
                }
            }
        }, 100);

        return () => clearInterval(stateCheck);
    }, []);

    return null;
}
