import Head from 'next/head';
import Link from 'next/link';

export default function Error({error}) {
    let {status, message, title} = error;

    switch (status) {
        case 400:
            if (!message) message = '400 - Bad Request';
            if (!title) title = '400';
            break;
        case 404:
            if (!message) message = '404 - Page Not Found';
            if (!title) title = '404';
            break;
        case 503:
            if (!message) message = '503 - Failed to Connect to Backend';
            if (!title) title = 'Server Error';
            break;
    }

    return <div className="p-3">
        <Head>
            <title>{`${title} - CornHub`}</title>
        </Head>
        <img src="https://cornhub.objectdepot.net/static/cornmaze.gif" alt="Corn Maze" className="m-auto"/>
        <h1 className="text-center text-2xl font-bold">{message || 'An error occured'}</h1>

        {status === 404 ? <h2 className="text-center text-lg">
            The page you're looking for doesn't exist!
            <br/>
            Click <Link href="/" className="text-primary underline">here</Link> to return to the homepage.
        </h2> : null}

        {status === 503 ? <h2 className="text-center text-lg">
            We're having some trouble connecting to our servers.
            <br/>
            Please <button onClick={() => window.location.reload()} className="text-primary underline">try again</button> later.
        </h2> : null}
    </div>;
}
